import imgAleynaGurbuz from "../images/team/aleyna-gurbuz.webp"
import imgAltanOzlu from "../images/team/altan-ozlu.png"
import imgAtaberkOzaydin from "../images/team/ataberk-ozaydin.webp"
import imgAtakangulBahar from "../images/team/atakan-gulbahar.webp"
import imgAybarsGocerler from "../images/team/aybars-gocerler.webp"
import imgYunusBalka from "../images/team/yunus-balka.webp"
import imgBurakTabakoglu from "../images/team/burak-tabakoglu.webp"
import imgBunyaminKorkut from "../images/team/bunyamin-korkut.webp"
import imgCemreBulut from "../images/team/cemre-bulut.png"
import imgEdaMutlu from "../images/team/eda-mutlu.webp"
import imgEfekanBicer from "../images/team/efekan-bicer.webp"
import imgEnesAliOvur from "../images/team/enes-ali-ovur.webp"
import imgEremKaragoz from "../images/team/erem-karagoz.webp"
import imgFurkanArslan from "../images/team/furkan-arslan.webp"
import imgIlhanHasimi from "../images/team/ilhan-hasimi.webp"
import imgKubraSekerTanyildizi from "../images/team/kubra-seker-tanyildizi.webp"
import imgNefiseSevvalGelen from "../images/team/nefise-sevval-gelen.png"
import imgNurettinResulTanyildizi from "../images/team/nurettin-resul-tanyildizi.webp"
import imgRamazanKapar from "../images/team/ramazan-kapar.webp"
import imgSelimGulce from "../images/team/selim-gulce.webp"
import imgUtkuKaragul from "../images/team/utku-karagul.webp"
import imgYilmazEv from "../images/team/yilmaz-ev.webp"
import imgZeynepTuran from "../images/team/zeynep-turan.webp"

export const NEWS = [
  {
    title: "newsFintech",
    desc: "fintechtime",
    link: "https://fintechtime.com/tr/2021/02/makromusic-2-milyon-dolar-degerleme-ile-ilk-yatirimini-aldi/",
    height: 630,
    img: "https://fintechtime.com/wp-content/uploads/2021/02/Macromusic.jpg",
  },
  {
    title: "newsSwipeline",
    desc: "swipeline",
    link: "https://swipeline.co/muzigin-sosyal-medyasi-makromusic-2-milyon-dolar-degerleme-ile-yatirim-aldi/",
    height: 300,
    img: "https://swipeline.co/wp-content/uploads/2021/02/makromusic_2.jpeg",
  },
  {
    title: "newsEgirisim",
    desc: "egirişim",
    link: "https://www.youtube.com/watch?v=NEoaINxkYts",
    height: 300,
    img: "https://i.ytimg.com/vi/NEoaINxkYts/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLDoUUx099B3HeYR1ONWT5TWxDgiag",
  },
  {
    title: "newsHilmi",
    desc: "Hilmi Öğütcü - egirişim",
    link: "https://egirisim.com/2021/02/02/muzik-sosyal-medyasi-makromusic-omer-erkmen-liderliginde-2-milyon-dolar-degerleme-ile-yatirim-aldi/",
    height: 630,
    img: "https://egirisim.com/wp-content/uploads/2021/02/makromusic-app.jpeg",
  },
  {
    title: "newsCapslock",
    desc: "capslock podcast",
    link: "https://www.youtube.com/watch?v=pTGpPIEK9Oo",
    height: 300,
    img: "https://i.ytimg.com/vi/pTGpPIEK9Oo/hq720.jpg?sqp=-oaymwEcCNAFEJQDSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLC7pIzIjh5bMKz0z0zwRblu4MHrwQ",
  },
  {
    title: "newsWebrazzi",
    desc: "webrazzi",
    link: "https://webrazzi.com/2021/02/02/universiteli-ogrenciler-tarafindan-kurulan-muzik-sosyal-medyasi-makromusic-2-milyon-dolar-degerleme-ile-yatirim-aldi/",
    height: 630,
    img: "https://cdn.webrazzi.com/uploads/2021/02/makromusic-619.png",
  },
]

export const TEAM_MEMBERS = [
  {
    name: 'Ataberk Özaydın',
    title: 'Co-Founder & CEO',
    img: imgAtaberkOzaydin,
    linkedin: 'https://www.linkedin.com/in/ataberkozaydin/',
  },
  {
    name: 'Atakan Gülbahar',
    title: 'Co-Founder & CTO',
    img: imgAtakangulBahar,
    linkedin: 'https://www.linkedin.com/in/atakangulbahar/',
  },
  {
    name: 'Aybars Göçerler',
    title: 'Co-Founder & Head of Backend Developer',
    img: imgAybarsGocerler,
    linkedin: 'https://www.linkedin.com/in/aybars-g%C3%B6%C3%A7erler-b89281195/',
  },
  {
    name: 'Altan Özlü',
    title: 'Co-Founder & Head of BackEnd Developer',
    img: imgAltanOzlu,
    linkedin: 'https://www.linkedin.com/in/altanozlu/',
  },
  {
    name: 'Erem Karagöz',
    title: 'Head of Product Design',
    img: imgEremKaragoz,
    linkedin: 'https://www.linkedin.com/in/eremkaragoz/',
  },
  {
    name: 'Nurettin Resul Tanyıldızı',
    title: 'Head of Mobile Application Developer',
    img: imgNurettinResulTanyildizi,
    linkedin: 'https://www.linkedin.com/in/resultanyildizi/',
  },
  {
    name: 'Aleyna Gürbüz',
    title: 'Head of Marketing',
    img: imgAleynaGurbuz,
    linkedin: 'https://www.linkedin.com/in/aleyna-g%C3%BCrb%C3%BCz/',
  },
  {
    name: 'Zeynep Turan',
    title: 'Head Of Customer Relations',
    img: imgZeynepTuran,
    linkedin: 'https://www.linkedin.com/in/zeynep-turan-771a341b7/',
  },
  {
    name: 'Ramazan Kapar',
    title: 'Head of Web Developer',
    img: imgRamazanKapar,
    linkedin: 'https://www.linkedin.com/in/ramazan-kapar-a2b313198/',
  },
  {
    name: 'Selim Gülce',
    title: 'Mobile Application Developer',
    img: imgSelimGulce,
    linkedin: 'https://www.linkedin.com/in/gulceselim/',
  },
  {
    name: 'İlhan Haşimi',
    title: 'Backend Team Lead',
    img: imgIlhanHasimi,
    linkedin: 'https://www.linkedin.com/in/junsred/',
  },
  {
    name: 'Enes Ali Övür',
    title: 'Mobile Application Developer',
    img: imgEnesAliOvur,
    linkedin: 'https://www.linkedin.com/in/enesaliovur/',
  },
  {
    name: 'Bünyamin Korkut',
    title: 'Frontend Developer',
    img: imgBunyaminKorkut,
    linkedin: 'https://www.linkedin.com/in/bnymnkrkt/',
  },
  {
    name: 'Burak Tabakoğlu',
    title: 'Backend Developer',
    img: imgBurakTabakoglu,
    linkedin: 'https://www.linkedin.com/in/buraktabako%C4%9Flu/',
  },
  {
    name: 'Furkan Arslan',
    title: 'Mobile Application Developer',
    img: imgFurkanArslan,
    linkedin: 'https://www.linkedin.com/in/furkan-arslan-/',
  },
  {
    name: 'Kübra Şeker Tanyıldızı',
    title: 'CR & Content Specialist',
    img: imgKubraSekerTanyildizi,
    linkedin: 'https://www.linkedin.com/in/kubrastanyildizi/',
  },
  {
    name: 'Utku Karagül',
    title: 'Backend Developer',
    img: imgUtkuKaragul,
    linkedin: 'https://www.linkedin.com/in/utku-karag%C3%BCl/',
  },
  {
    name: 'Yılmaz Ev',
    title: 'Jr. Web Developer',
    img: imgYilmazEv,
    linkedin: 'https://www.linkedin.com/in/yilmazev/',
  },
  {
    name: 'Yunus Balka',
    title: 'Mobile Application Developer',
    img: imgYunusBalka,
    linkedin: 'https://www.linkedin.com/in/yunus-balka-a0615a207/',
  },
  {
    name: 'Efekan Biçer',
    title: 'Backend Developer',
    img: imgEfekanBicer,
    linkedin: 'https://www.linkedin.com/in/efekan-bi%C3%A7er/',
  },
  {
    name: 'Eda Mutlu',
    title: 'Jr. Product Designer',
    img: imgEdaMutlu,
    linkedin: 'https://www.linkedin.com/in/eda-mutlu-0b4255211/',
  },
  {
    name: 'Nefise Şevval Gelen',
    title: 'Intern Marketing',
    img: imgNefiseSevvalGelen,
    linkedin: 'https://www.linkedin.com/in/nefise-%C5%9Fevval-gelen-9407852ba/',
  },
  {
    name: 'Cemre Bulut',
    title: 'Intern Content',
    img: imgCemreBulut,
    linkedin: 'https://www.linkedin.com/in/cemrebulutt/',
  },
]